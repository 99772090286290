.landing-text1{
    font-weight: 500;
    font-size: 22px;
    line-height: 26.4px;
    letter-spacing: 0%;
    text-align: center;
    color: #000034;
}

.landing-head{
    font-weight: 700;
    font-size: 52px;
    line-height: 62.4px;
    letter-spacing: 0%;
    text-align: center;    
}

.landing-text2{
font-weight: 500;
font-size: 30px;
line-height: 42px;
letter-spacing: 0%;
text-align: center;
}

.landing-text3{
    font-weight: 600;
    font-size: 42px;
    line-height: 58.8px;
    letter-spacing: 0%;
    text-align: center;

}